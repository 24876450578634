.pop-hover-container-left {
    position: relative;
    display: inline-block;
  }
  
  .pop-hover-text-left {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    transform-origin: bottom left;
    padding: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    z-index: 999;
  }  