.pop-hover-container {
    position: relative;
    display: inline-block;
  }
  
  .pop-hover-text {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    z-index: 999;
  }