.button1{
    margin-top: 18px;
    width: 195px;
    height: 45px;
    border-radius: 8px;
    background-color: #043F54;
    color: #FFF;
    line-Height:1.2;
    border: 1px;
    letter-spacing: 0.02857em;
    cursor: pointer;
}

.button1:hover{
        background-color: #03506B;
        color: #FFF;
   
}

.button1:focus { 
        outline: none;
        color: #FFF;
}