.challenge-box {
    background-color: #0288d1;
    border-radius: 32px;
    padding: 16px;
    text-align: center;
    color: #fff;
    
  }
  @media (min-width : 1177px){
    .challenge-box{
        width: 700px;
    }
    .center{
        padding-left: 15%;
    }
    
  }
  .centered-text {
    margin: 0;
    font-size: 18px;
  }
  .center {
    text-align: center;
    justify-content: center;

  }

 