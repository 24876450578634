.user-list {}

.user-list .dataTables_filter label {
  width:100%;
}

.user-list .btn-date span svg {
  margin-right:6px;
}
.user-list .btn-date span span {
  text-transform: none;
  font-size:14px;
}

.user-list .card {
  margin-top:15px;
}

.user-list .user-list__controls {
  margin-top:18px;
}
.user-list .user-list__controls .btn-date {
  margin-bottom:10px;
}

