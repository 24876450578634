.result-list {}

.result-list .dataTables_filter label {
  width:100%;
}

.result-list .btn-date span svg {
  margin-right:6px;
}
.result-list .btn-date span span {
  text-transform: none;
  font-size:14px;
}

.result-list .card {
  margin-top:15px;
}

.result-list .result-list__controls {
  margin-top:18px;
}
.result-list .result-list__controls .btn-date {
  margin-bottom:10px;
}

