.tug-dialog {}

.tug-dialog .tug-dialog__title {
  border-bottom: solid 1px #D1D1D1;
  padding: 20px 20px 18px 20px;
}

.tug-dialog .tug-dialog__title h2 {
  font-size:18px;
  font-weight:400;
}

.tug-dialog .tug-dialog__button:focus {
  outline: none;
}

.tug-dialog .tug-dialog__close {
  position: absolute;
  right: 7px;
  top: 9px;
  color: #595959;
}

.tug-dialog .tug-dialog__footer {
  border-top:solid 1px #D1D1D1;
  margin:0;
  margin-top:25px;
  padding: 12px 18px;
}

.tug-dialog .dataTables_filter {
  max-width:41.666667%;
  padding:0;
  margin-top:10px;
  margin-bottom:5px;
}
.tug-dialog .dataTables_filter label {
  width:100%;
}

.tug-dialog .dataTables_filter .form-control,
.tug-dialog .dataTables_filter .is-focused .form-control {
  background-image: linear-gradient(to top, #595959 2px, rgba(89, 89, 89, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}



