.gams-trl {}

.gams-trl .gams-trl__paper {
  width:100%;
  height:354px;
  overflow: auto;
}
@media only screen and (max-width: 959.95px) {
  .gams-trl .gams-trl__paper {
    height:250px;
  }
}

.gams-trl .gams-trl__label {
  font-size:14px;
  color:#121212;
  margin-bottom:3px;
  font-weight:400;
}

.gams-trl .gams-trl__item {
  padding-top:0px;
  padding-bottom:0px;
  padding-left:3px;
  padding-right:3px;
}

.gams-trl .gams-trl__item div:first-child {
  margin-right:0;
}

.gams-trl .gams-trl__item div:nth-child(2) {
  font-size:14px;
  color:#595959;
  padding:0;
}
